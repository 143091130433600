export default [
  {
    path: '/learn/csharp',
    title: 'Intro',
    name: 'Intro',
    file: '../views/learn/CSharp/Intro.vue'
  },
  {
    path: '/learn/csharp/1',
    title: 'Install Visual Studio',
    name: 'InstallVisualStudio',
    file: '../views/learn/CSharp/InstallVisualStudio.vue'
  },
  {
    path: '/learn/csharp/2',
    title: 'Data Types',
    name: 'DataTypes',
    file: '../views/learn/CSharp/DataTypes.vue'
  },
  {
    path: '/learn/csharp/3',
    title: 'Console Output',
    name: 'ConsoleOutput',
    file: '../views/learn/CSharp/ConsoleOutput.vue'
  },
  {
    path: '/learn/csharp/4',
    title: 'If Statement',
    name: 'IfStatement',
    file: '../views/learn/CSharp/IfStatement.vue'
  },
  {
    path: '/learn/csharp/5',
    title: 'For Loop',
    name: 'ForLoop',
    file: '../views/learn/CSharp/ForLoop.vue'
  },
  {
    path: '/learn/csharp/6',
    title: 'While Loop',
    name: 'WhileLoop',
    file: '../views/learn/CSharp/WhileLoop.vue'
  },
  {
    path: '/learn/csharp/7',
    title: 'Functions',
    name: 'Functions',
    file: '../views/learn/CSharp/Functions.vue'
  },
  {
    path: '/learn/csharp/8',
    title: 'Arrays[]',
    name: 'Arrays',
    file: '../views/learn/CSharp/Arrays.vue'
  },
  {
    path: '/learn/csharp/9',
    title: 'Lists<>',
    name: 'Lists',
    file: '../views/learn/CSharp/Lists.vue'
  },
  {
    path: '/learn/csharp/10',
    title: 'Structures',
    name: 'Structures',
    file: '../views/learn/CSharp/Structures.vue'
  },
  {
    path: '/learn/csharp/11',
    title: 'Classes',
    name: 'Classes',
    file: '../views/learn/CSharp/Classes.vue'
  },
  {
    path: '/learn/csharp/12',
    title: 'Inheritance',
    name: 'Inheritance',
    file: '../views/learn/CSharp/Inheritance.vue'
  },
  {
    path: '/learn/csharp/13',
    title: 'Abstract',
    name: 'Abstract',
    file: '../views/learn/CSharp/Abstract.vue'
  },
  {
    path: '/learn/csharp/14',
    title: 'Interface',
    name: 'Interface',
    file: '../views/learn/CSharp/Interface.vue'
  },
  {
    path: '/learn/csharp/15',
    title: 'Enum',
    name: 'Enum',
    file: '../views/learn/CSharp/Enum.vue'
  },
  {
    path: '/learn/csharp/16',
    title: 'Static Classes',
    name: 'StaticClasses',
    file: '../views/learn/CSharp/StaticClasses.vue'
  },
  {
    path: '/learn/csharp/17',
    title: 'Classes Overview',
    name: 'ClassesOverview',
    file: '../views/learn/CSharp/ClassesOverview.vue'
  },
  {
    path: '/learn/csharp/18',
    title: 'Polymorphism',
    name: 'Polymorphism',
    file: '../views/learn/CSharp/Polymorphism.vue'
  },
  {
    path: '/learn/csharp/19',
    title: 'TryParse',
    name: 'TryParse',
    file: '../views/learn/CSharp/TryParse.vue'
  },
  {
    path: '/learn/csharp/20',
    title: 'Exceptions',
    name: 'Exceptions',
    file: '../views/learn/CSharp/Exceptions.vue'
  },
  {
    path: '/learn/csharp/21',
    title: 'Debugging',
    name: 'Debugging',
    file: '../views/learn/CSharp/Debugging.vue'
  },
  {
    path: '/learn/csharp/22',
    title: 'Regions',
    name: 'Regions',
    file: '../views/learn/CSharp/Regions.vue'
  },
  {
    path: '/learn/csharp/23',
    title: 'Ref Parameter',
    name: 'RefParameter',
    file: '../views/learn/CSharp/RefParameter.vue'
  },
  {
    path: '/learn/csharp/24',
    title: 'Out Parameter',
    name: 'OutParameter',
    file: '../views/learn/CSharp/OutParameter.vue'
  },
  {
    path: '/learn/csharp/25',
    title: 'Optional Parameter',
    name: 'OptionalParameter',
    file: '../views/learn/CSharp/OptionalParameter.vue'
  },
  {
    path: '/learn/csharp/26',
    title: 'Named Parameter',
    name: 'NamedParameter',
    file: '../views/learn/CSharp/NamedParameter.vue'
  },
  {
    path: '/learn/csharp/27',
    title: 'Conditional Operator',
    name: 'Conditional Operator',
    file: '../views/learn/CSharp/ConditionalOperator.vue'
  },
  {
    path: '/learn/csharp/28',
    title: 'Overriding ToString()',
    name: 'OverridingToString',
    file: '../views/learn/CSharp/OverridingToString.vue'
  }
];