<template>
  <div class="main w-full flex mb-flex-col mb-h-full mb-m-0">
    <div :class="`nav w-500 color-white flex-center-start-col overflow-y-scroll mb-w-auto ${mobileExpand ? 'mb-h-350' : ''}`">
      <div class="flex-center-col">
        <GoBack class="mt-25" path='/learn' />
        <h1 class="font-48 font-popThin m-10">C#</h1>
        <a class="btn-blue desktopHide bg-dark-gray border-white-faint mb-10" @click="mobileExpand = !mobileExpand">{{ mobileExpand ? 'Hide' : 'Expand' }} <i :class="`arrow ${mobileExpand ? 'downArrow' : 'upArrow'}`"></i></a>
      </div>
      <div class="flex-center-col" v-show="mobileExpand">
        <p class="noSelect text-center m-0 mb-15 cursor-pointer max-w-250 font-18" v-for="lesson in lessons" v-bind:key="lesson.id" @click="route(lesson.path)">{{ lesson.title }}</p>
      </div>
    </div>
    <router-view class="bg-white w-full radius-15 p-25 m-25 ml-5 mb-w-auto mb-m-15"/>
  </div>
</template>

<script>
import lessons from '@/scripts/tutorials/csharp.js';
import { isMobile, returnMedia, scrollTop } from '@/scripts/helper.js'
import { goTo } from '@/scripts/routeHelper.js'
import GoBack from '@/components/GoBack.vue'

export default {
  components: {
    GoBack
  },
  data: function() {
    return {
      lessons,
      mobileExpand: false
    }
  },
  watch:{
    $route (to){
      this.setActive(to.path);
    }
  },
  mounted: function() {
    document.title = 'C# | Code with Aba';
    scrollTop();
    this.setActive(window.location.pathname);

    this.mobileExpand = !isMobile();

    (returnMedia()).onchange = (e) => {
      this.mobileExpand = !e.matches;
    }
  },
  methods: {
    setActive(path) {
      var index = path.substring(path.lastIndexOf('/') + 1, path.length);

      var list = document.getElementsByTagName('p');

      for (let i = 0; i < list.length; i++) {
        list[i].classList.remove('font-popSemiBold');
      }

      if (!list[index]) {
        index = 0;
      }

      list[index].classList.add('font-popSemiBold');
    },
    route: function(path) { goTo(path); }
  }
}
</script>

<style scoped>
.main {
  height: calc(100vh - 71px);
  background-color: var(--chill-blue-color);
}
.nav::-webkit-scrollbar {
  width: 10px;
}
.nav::-webkit-scrollbar-track {
  background: var(--chill-blue-color);
  margin: 5px 0;
}
.nav::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 5px;
}
.nav::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
